<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>UoM Group Definition</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          text
          to="/inventory/uom-groups"
        >
          <v-icon
            left
            dark
          >mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn
              @click="newGroup"
              color="primary"
              small
            >
              <v-icon left>mdi-plus</v-icon>New Group Definition
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Alt Qty.</th>
                    <th class="text-left">Alt UoM</th>
                    <th class="text-left">=</th>
                    <th class="text-left">Base Qty.</th>
                    <th class="text-left">Base UoM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in groupDef"
                    v-bind:key="index"
                  >
                    <td>
                      {{ item.AltQty }}
                    </td>
                    <td>
                      {{ item.uomentry.UomName }}
                    </td>
                    <td>=</td>
                    <td>{{ item.BaseQty }}</td>
                    <td>{{ item.baseuom.ouom.UomName }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <!-- dialog -->
        <v-dialog
          width="1050"
          v-model="defDialog"
        >
          <v-toolbar
            dense
            color="accent"
            dark
          >
            <v-toolbar-title>New Group Definition</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click.native="defDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Alt Qty."
                    v-model="defItem.AltQty"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-autocomplete
                    v-model="defItem.UomEntry"
                    :items="uom"
                    item-text="UomName"
                    item-value="id"
                    label="Alt UoM"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Base Qty."
                    v-model="defItem.BaseQty"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Base UoM"
                    v-if="groupItem.ouom"
                    v-model="groupItem.ouom.UomName"
                    readonly
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="accent"
                    small
                    @click="createugp1"
                    :loading="loader"
                  >
                    <v-icon left>mdi-content-save</v-icon> Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    loader: false,
    dataItem: {},
    groupItem: {},
    defItem: {},
    groupDef: [],
    uom: [],
    defDialog: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "getGroupData",
      immediate: true,
    },
  },
  methods: {
    newGroup() {
      this.defDialog = true;
    },
    createugp1() {
      const data = {
        UgpEntry: this.groupItem.id,
        UomEntry: this.defItem.UomEntry,
        AltQty: this.defItem.AltQty,
        BaseQty: this.defItem.BaseQty,
      };
      const url = "/createugp1";
      this.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getUom() {
      const self = this;
      this.$store
        .dispatch("get", `/uom`)
        .then((res) => {
          self.uom = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getGroupData(val) {
      const self = this;
      this.$store
        .dispatch("get", `/uomgroup/${val}`)
        .then((res) => {
          self.groupItem = res.ResponseData;
          self.groupDef = res.ResponseData.ugp1;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const url = "/uomgroup";
      const self = this;
      this.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/inventory/uom-groups");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getUom();
  },
};
</script>